import React from 'react';

// components
import Layout from '@src/layouts';
import SectionTitle from '@components/atoms/SectionTitle';
import Detail from '@components/molecules/Detail';
import HeroPage from '@components/molecules/heros/HeroPage';

// assets
import imgEight from '@assets/images/eight.svg';

/**
 * Content related vars
 */

const pageClass = 'page-jobs';

const language = 'en';

const footerRelatedLinks = [
	{
		title: 'Contact',
		url: '/en/contact/',
	},
	{ title: 'Work', url: '/en/projects/' },
];

const ContactPage = (props) => (
	<Layout
		language={language}
		pageClass={pageClass}
		footerRelatedLinks={footerRelatedLinks}
		location={props.location}
		translations={[{ uri: '/fr/rejoindre/', lang: 'fr' }]}
		title="Open Positions - Superhuit"
	>
		<HeroPage image={imgEight} imageY={54} title="Join Us" />
		<section className="section-jobs">
			<SectionTitle title="Open Positions" />

			<div className="grid">
				<div className="box text-content" data-animation-page>
					<p className="uptitle">Open Positions</p>
					<div className="list-two-columns element-spacing">
						{/* <Detail
							title="Brand Project Manager"
							text={[
								'Manage brand projects, grow the company’s clients portfolio and represent our highly-skilled team in Lausanne.',
							]}
							link={{
								title: 'In details',
								url: '/en/jobs/brand-project-manager/',
							}}
						/> */}
						{/* <Detail
							title="Web Project Manager"
							text={[
								'Manage high-quality projects with our creative team in Lausanne.',
								// 'Location: Lausanne Office, Switzerland',
							]}
							link={{
								title: 'In details',
								url: '/en/jobs/web-project-manager/',
							}}
						/> */}
						<Detail
							title="Tech Lead"
							text={[
								'Build the future of the agency’s technical offering, in close collaboration with the team and partners.',
								// 'Où: Lausanne, Suisse',
							]}
							link={{
								title: 'Know more',
								url: '/en/jobs/tech-lead/',
							}}
						/>
						{/* <Detail
							title="Back-end Web Developer"
							text={[
								'Build carefully crafted websites in PHP and Javascript with our highly-skilled team in Lausanne.',
								// 'Location: Porto Office, Portugal',
							]}
							link={{
								title: 'In details',
								url: '/en/jobs/backend-developer/',
							}}
						/> */}
						{/* <Detail
							title="Front-end Web Developer"
							text={[
								'Develop outstanding web experiences with our a creative team in Lausanne.',
								// 'Location: Porto Office, Portugal',
							]}
							link={{
								title: 'In details',
								url: '/en/jobs/frontend-developer/',
							}}
						/> */}
					</div>
				</div>
			</div>
		</section>
	</Layout>
);

export default ContactPage;
